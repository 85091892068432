var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "pt-0 overflow-y-auto",
    attrs: {
      "fluid": ""
    }
  }, [_c('v-row', [_c('v-col', [_c('v-btn', {
    staticClass: "primary",
    on: {
      "click": _vm.create
    }
  }, [_c('v-icon', [_vm._v("mdi-plus")]), _vm._v("New Tax")], 1)], 1)], 1), _vm._l(_vm.items, function (tax) {
    return _c('v-row', {
      key: tax.xid,
      staticClass: "mb-1",
      attrs: {
        "xclass": ""
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "12",
        "lg": "6",
        "xl": "4"
      }
    }, [_c('v-card', {
      staticClass: "tier py-2",
      attrs: {
        "disabled": _vm.loading
      },
      on: {
        "click": function click($event) {
          return _vm.clickEdit(tax);
        }
      }
    }, [_c('div', {
      staticClass: "duplicate cardbtn",
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.duplicate(tax);
        }
      }
    }, [_c('v-icon', {
      attrs: {
        "small": ""
      }
    }, [_vm._v("mdi-content-copy")])], 1), _c('div', {
      staticClass: "delete cardbtn",
      on: {
        "click": function click($event) {
          $event.stopPropagation();
          return _vm.Delete(tax);
        }
      }
    }, [_c('v-icon', {
      attrs: {
        "small": ""
      }
    }, [_vm._v("mdi-trash-can-outline")])], 1), _c('v-list-item', {
      staticClass: "pl-4 pr-14"
    }, [_c('v-list-item-content', {
      staticClass: "py-1"
    }, [_c('v-list-item-title', {
      staticClass: "text-body-1 py-1 font-weight-medium"
    }, [_vm._v(" " + _vm._s(tax.title) + " (" + _vm._s(parseFloat(tax.rate)) + "%) ")]), _c('v-list-item-subtitle', {
      domProps: {
        "textContent": _vm._s(tax.description)
      }
    })], 1)], 1)], 1)], 1)], 1);
  }), _vm.editItem.show ? _c('v-dialog', {
    attrs: {
      "max-width": "450",
      "persistent": ""
    },
    model: {
      value: _vm.editItem.show,
      callback: function callback($$v) {
        _vm.$set(_vm.editItem, "show", $$v);
      },
      expression: "editItem.show"
    }
  }, [_c('v-card', {
    attrs: {
      "elevation": "20",
      "disabled": _vm.editItem.loading
    }
  }, [_c('v-card-title', {
    staticClass: "text-h6 primary white--text py-3"
  }, [_vm._v(" Update Tax ")]), _c('v-card-text', {
    staticClass: "pt-8 text-subtitle-2"
  }, [_c('v-row', {
    staticStyle: {}
  }, [_c('v-col', {
    staticStyle: {},
    attrs: {
      "cols": "8"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "label": "Tax",
      "maxlength": "30"
    },
    model: {
      value: _vm.editItem.title,
      callback: function callback($$v) {
        _vm.$set(_vm.editItem, "title", $$v);
      },
      expression: "editItem.title"
    }
  })], 1), _c('v-col', {
    staticStyle: {},
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "dense": "",
      "hide-details": "",
      "label": "Rate",
      "prefix": "%"
    },
    model: {
      value: _vm.editItem.rate,
      callback: function callback($$v) {
        _vm.$set(_vm.editItem, "rate", $$v);
      },
      expression: "editItem.rate"
    }
  })], 1), _c('v-col', {
    staticStyle: {},
    attrs: {
      "cols": "12"
    }
  }, [_c('v-textarea', {
    attrs: {
      "value": _vm.editItem.description,
      "outlined": "",
      "dense": "",
      "label": "Description",
      "counter": "100",
      "maxLength": "100"
    },
    on: {
      "change": function change($event) {
        _vm.editItem.description = $event;
      }
    }
  })], 1)], 1)], 1), _c('v-card-actions', {
    staticClass: "pb-4"
  }, [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "color": "secondary darken-1"
    },
    on: {
      "click": function click($event) {
        _vm.editItem.show = false;
      }
    }
  }, [_vm._v("Cancel")]), _c('v-btn', {
    attrs: {
      "color": "primary darken-1"
    },
    on: {
      "click": _vm.updateItem
    }
  }, [_vm._v("Update")])], 1)], 1)], 1) : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }