<template>
  <v-container fluid class='pt-0 overflow-y-auto'>

    <v-row>
      <v-col>
        <v-btn class='primary' @click='create'><v-icon>mdi-plus</v-icon>New Tax</v-btn>
      </v-col>
    </v-row>

    <v-row class='mb-1' v-for="tax in items" v-bind:key="tax.xid" xclass=''>

      <v-col cols=12 lg=6 xl=4 class=''>
        <v-card class='tier py-2' :disabled='loading' @click='clickEdit(tax)'>
          <div class='duplicate cardbtn' @click.stop="duplicate(tax)"><v-icon small>mdi-content-copy</v-icon></div>
          <div class='delete cardbtn' @click.stop="Delete(tax)"><v-icon small>mdi-trash-can-outline</v-icon></div>

          <v-list-item class='pl-4 pr-14'>
            <v-list-item-content class='py-1'>
              <v-list-item-title class='text-body-1 py-1 font-weight-medium'>
                {{tax.title}} ({{parseFloat(tax.rate)}}%)
              </v-list-item-title>
              <v-list-item-subtitle v-text='tax.description' />
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>


    </v-row>


    <v-dialog v-model="editItem.show" v-if='editItem.show' max-width="450" persistent>
      <v-card elevation="20" :disabled='editItem.loading'>
        <v-card-title class="text-h6 primary white--text py-3">
          Update Tax
        </v-card-title>
        <v-card-text class='pt-8 text-subtitle-2'>
          <v-row style='py-0'>

            <v-col cols=8 style='py-0'><v-text-field v-model='editItem.title' outlined dense hide-details label='Tax' maxlength=30 /></v-col>
            <v-col cols=4 style='py-0'><v-text-field v-model='editItem.rate' outlined dense hide-details label='Rate' prefix="%" /></v-col>
            <v-col cols=12 style='py-0'><v-textarea :value='editItem.description' @change="editItem.description = $event" outlined dense label='Description' counter="100" maxLength="100" /></v-col>

          </v-row>
        </v-card-text>
        <v-card-actions class='pb-4'>
          <v-spacer></v-spacer>
          <v-btn color="secondary darken-1" @click='editItem.show = false;'>Cancel</v-btn>
          <v-btn color="primary darken-1" @click='updateItem'>Update</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<style lang="scss">
@import "@/assets/common";
.tier{
  user-select: none;
  .cardbtn{
    position:absolute;
    z-index:1;
    top: 6px;
    &.dragMe{
      cursor: move;
      left:4px;
    }
    &.duplicate{
      cursor: pointer;
      right:30px;
      &:hover .v-icon{ color: $primary; }
    }
    &.delete{
      cursor: pointer;
      right:8px;
      &:hover .v-icon{ color: $primary; }
    }
    &.tax{
      top: auto;
      bottom: 4px;
      right: 10px;
    }
  }
}
</style>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'Taxes',

  components: {
  },

  data: () => ({
    loading: false,
    items: [],
    editItem: {
      show: false,
      loading: false,
      title: null,
      rate: null,
      description: null,
    },
  }),

  computed: {
  },

  methods:{
    ...mapActions('API', ['API']),
    ...mapActions('UI', ['PROMPT', 'CONFIRM']),

    refreshData(){
      this.loading = true;
      return this.API({ method: 'GET', endpoint: `taxes` })
      .then(items => {
        this.items.splice(0);
        this.$nextTick(() => { this.items = items; });
      })
      .finally(() => { this.loading = false; });
    },

    clickEdit(item){
      this.editItem = {
        ...this.editItem,
        ...item,
        show: true,
      };
    },

    updateItem(){
      this.editItem.loading = true;
      this.API({ method: 'PATCH', endpoint: `taxes/${this.editItem.xid}`, data: {
        title: this.editItem.title,
        rate: this.editItem.rate,
        description: this.editItem.description,
      }})
      .then(newData => {
        this.items[this.items.findIndex(t=>t.xid == newData.xid)] = newData;
        this.editItem.show = false;
      })
      .finally(() => { this.editItem.loading = false; });
    },

    duplicate(t){
      this.CONFIRM({ title: "Duplicate Tax", message: `Duplicate Tax ${t.title}?` })
      .then(() => {
        this.API({ method: 'POST', endpoint: `taxes/${t.xid}/duplicate` })
        .then(newItem => {
          this.refreshData()
          .then(() => { this.clickEdit(newItem); });
        });
      });
    },

    Delete(t){
      this.CONFIRM({ title: "Delete?", message: `Delete tax ${t.title}?`})
      .then(() => {
        this.API({ method: 'DELETE', endpoint: `taxes/${t.xid}` })
        .then(this.refreshData);
      });
    },

    create(){
      this.API({ method: 'POST', endpoint: `taxes/create` })
      .then(newItem => {
        this.refreshData()
        .then(() => { this.clickEdit(newItem); });
      });

    },

  },

  mounted() {
    this.refreshData();
  },

};
</script>
